import styled from "styled-components";
import { useEffect } from "react";

const Modal = ({ children, onClose }) => {
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => (document.body.style = `overflow: auto`);
  }, []);

  return (
    <>
      <Dim />
      <Content>
        <CloseButton type="button" onClick={onClose}>
          닫기
        </CloseButton>
        {children}
      </Content>
    </>
  );
};

export const Dim = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  z-index: 98;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  z-index: 99;
`;

export const Iframe = styled.iframe`
  border: none;

  @media screen and (max-width: 850px) {
    width: 660px;
    height: 400px;
  }

  @media screen and (max-width: 450px) {
    width: 330px;
    height: 180px;
  }
`;

const CloseButton = styled.button`
  width: fit-content;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background-color: #222e38;
  color: white;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  padding: 4px 8px;
  margin-left: auto;
`;

export default Modal;
